<template>
  <ProductSwiperColumn2
    v-if="isMobileSwiperColumn2"
    ref="codeProduct"
    :data-list="dataList"
    :operation-data="operationData || data.operationData"
    :slides-per-view="Number(data.content.columns) || 1"
    :layout="layout"
    :adv-img-url="advImgUrl"
    :ad-occupies-columns="advImgCoverColumn || 2"
    :adv-img-jump-link="data.content && data.content.advImgJumpLink"
  />
  <ProductSwiper
    v-else-if="productDisplayType === 'swiper'"
    ref="codeProduct"
    :data-list="dataList"
    :operation-data="operationData || data.operationData"
    :slides-per-view="Number(data.content.columns) || 1"
    :layout="layout"
    :adv-img-url="advImgUrl"
    :ad-occupies-columns="advImgCoverColumn || 2"
    :adv-img-jump-link="data.content && data.content.advImgJumpLink"
  ></ProductSwiper>
  <ProductList
    v-else
    ref="codeProduct"
    :data-list="dataList"
    :columns="columns"
    :adv-img-url="advImgUrl"
    :ad-occupies-columns="advImgCoverColumn || 2"
    :adv-img-jump-link="data.content && data.content.advImgJumpLink"
    :operation-data="operationData || data.operationData"
    :is-slide="isSlide"
  />
</template>

<script>
import { componentsCommon } from '@speedshop/template';
import ProductSwiper from './productListSwiper.vue';
import ProductList from './productList.vue';
import ProductSwiperColumn2 from './productSwiperColumn2.vue';
import { CatalogProduct } from '@/utils/product';
import PLP from '@/components/PLP/plp.vue';
export default {
  components: {
    ProductSwiper,
    ProductList,
    ProductSwiperColumn2
  },
  mixins: [componentsCommon],
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    layout: {
      type: Object,
      default: () => {
        return {};
      }
    },
    operationData: {}
  },
  data() {
    return {
      plp: null,
      dataList: [],
      rerender: false,
      fetched: false
    };
  },
  computed: {
    sendParams() {
      return {
        ...this.generateFilter(),
        conditionKeyword: '',
        prefetch: true,
        pageSize: 10,
        pageNum: 1
      };
    },
    productDisplayType() {
      const widgetItem = this.data?.content?.widgetItem || {};
      const { layoutId } = this.data;
      const { widgetCode } = widgetItem;
      if (
        ['widget-product-swiper', 'widget-product-swiper-with-bg'].includes(widgetCode) ||
        ['widget-product-swiper-with-img'].includes(layoutId)
      ) {
        return 'swiper';
      } else {
        return 'list';
      }
    },
    isDisplayFilter() {
      return this.data?.content?.isDisplayFilter;
    },
    isDisplaySort() {
      return this.data?.content?.isDisplayFilter;
    },
    isDisplayAdvImg() {
      return this.data?.content?.isDisplayAdvImg;
    },
    advImgUrl() {
      return this.data?.content?.advImgUrl;
    },
    columns() {
      return this.data?.content?.columns;
    },
    advImgCoverColumn() {
      return this.data?.content?.advImgCoverColumn;
    },
    isSlide() {
      const widgetItem = this.data?.content?.widgetItem || {};
      const { layoutId } = this.data;
      const { widgetCode } = widgetItem;

      return ['widget-product-slide', 'widget-product-slide-dark'].includes(widgetCode);
    },
    isMobileSwiperColumn2() {
      const widgetItem = this.data?.content?.widgetItem || {};
      const { layoutId } = this.data;
      const { widgetCode } = widgetItem;

      return ['widget-product-swiper-with-2-columns'].includes(widgetCode);
    }
  },
  created() {
    this.getPlp(this.generateFilter());
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.lazyLoadProduct);
  },
  mounted() {
    // 滚动才加载，先不做，接口加了缓存
    //
    // this.$nextTick(() => {
    //   this.lazyLoadProduct();
    // });
    // window.addEventListener('scroll', this.lazyLoadProduct);
  },
  methods: {
    lazyLoadProduct() {
      if (this.fetched) return;
      const $container = this.$refs.codeProduct?.$el;
      const isInViewport = this.isInViewport($container);
      console.log(isInViewport, 888);
      if (!isInViewport) return;
      window.removeEventListener('scroll', this.lazyLoadProduct);
      this.fetched = true;
      this.getPlp(this.generateFilter());
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect();

      return (
        rect.bottom > 0 &&
        rect.top < (window.innerHeight || document.documentElement?.clientHeight) + 200
      );
    },
    // WCM配置的过滤数据
    generateFilter() {
      const list = (this?.data?.content?.productCodeList || []).map(v => v.productCode);
      const topProductCodeList = (this?.data?.content?.topProducts || []).map(v => v.productCode);
      return {
        conditionKeyword: '',
        productCodeList: list,
        // topProductCodeList,
        prefetch: true,
        pageSize: 20,
        pageNum: 1,
        hasFilter: this.isDisplayFilter
      };
    },

    async getPlp(data) {
      this.rerender = false;
      const wcmProductReqList = (this?.data?.content?.productCodeList || []).map(v => {
        return {
          productCode: v.productCode,
          sellingPoint: v.additionalInfo,
          shortName: v.shortName
        };
      });

      const Plp = CatalogProduct({
        // isCache: data.productCodeList && data.productCodeList.length,
        isCache: true,
        isMobile: this.$store.getters.isMobile,
        hasFilter: false,
        wcmProductReqList
      });
      //   await Plp?.checkCondition()
      const { dataList = [] } = await Plp.init({
        ...data,
        hasFilter: false
      });

      this.dataList = dataList.map((item, index) => {
        const productList = item.productList.map(i => {
          return {
            ...i,
            operationData: {
              ...(this.operationData ? this.operationData : this.data.operationData),
              product_rank: index + 1,
              ...i.trackData
              // product_rank: this.data.operationData.operation_rank
            }
          };
        });
        return {
          ...item,
          productList,
          operationData: {
            ...this.data.operationData,
            ...item.trackData,
            product_rank: index + 1
            // product_rank: this.data.operationData.operation_rank
          }
        };
      });
    }
  }
};
</script>

<style>
.sp-plp-page.product-list-code {
  min-height: 0px;
}
</style>
