import dayjs from 'dayjs';
import lo from 'lodash';

/**
 * 商品属性转为MAP
 */
export function toMap(attributes) {
  return lo
    .chain(attributes)
    .map(({ attrCode, attrName, attrValue, attributeValues }, index) => {
      const data = attributeValues
        ? [{ valueCode: attributeValues, valueName: attributeValues }]
        : attrValue;
      const { valueName, valueCode } = (data && data[0]) || {};
      return {
        attrCode,
        attrName,
        valueName,
        valueCode,
        index,
        isIntro: lo.includes(attrCode, 'PDP_INTRO')
      };
    })
    .keyBy('attrCode')
    .value();
}

/**
 * 从商品属性中判断是否包含最终到手价
 */
export function getFinalPrice({ attributes = [] } = {}) {
  const mapData = toMap(attributes);
  const finalPrice = Number(mapData.SHOW_ESTIMATED_PRICE?.valueCode);
  return {
    // 最终价
    finalPrice,
    // 是否显示最终价
    isShowFinalPrice:
      finalPrice > 0 &&
      (mapData.SHOW_ESTIMATED_PRICE_START_TIME?.valueCode
        ? dayjs().isAfter(mapData.SHOW_ESTIMATED_PRICE_START_TIME.valueCode)
        : true) &&
      (mapData.SHOW_ESTIMATED_PRICE_END_TIME?.valueCode
        ? dayjs().isBefore(mapData.SHOW_ESTIMATED_PRICE_END_TIME.valueCode)
        : true)
  };
}
