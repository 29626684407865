<template>
  <PLP
    :preview-data="plp"
    :params="sendParams"
    :hidden-filter="!isDisplayFilter"
    :is-display-sort="isDisplaySort"
    :adv-img-url="advImgUrl"
    :wcm-columns="columns"
    :ad-occupies-columns="advImgCoverColumn || 2"
    :title="pageData.content && pageData.content.productListName"
    class="condition-product-list"
  />
</template>

<script>
import { generateFilterArr } from '@speedshop/template';
import PLP from '@/components/PLP/plp.vue';
import { CatalogProduct } from '@/utils/product';
export default {
  components: {
    PLP
  },
  props: {
    pageData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      plp: null
    };
  },
  computed: {
    sendParams() {
      return {
        ...this.generateFilter(),
        prefetch: true,
        pageSize: 10,
        pageNum: 1
      };
    },
    isDisplayFilter() {
      return this.pageData?.content?.isDisplayFilter;
    },
    isDisplaySort() {
      return this.pageData?.content?.isDisplaySort;
    },
    isDisplayAdvImg() {
      return this.pageData?.content?.isDisplayAdvImg;
    },
    advImgUrl() {
      return this.pageData?.content?.advImgUrl;
    },
    columns() {
      return this.pageData?.content?.columns;
    },
    advImgCoverColumn() {
      return this.pageData?.content?.advImgCoverColumn;
    }
  },
  async created() {
    const data = this.generateFilter();
    await this.getPlp(data);
  },
  methods: {
    // WCM配置的过滤数据
    generateFilter() {
      if (this?.pageData?.content?.type || this?.pageData?.type) {
        this.type = this?.pageData?.content?.type || this?.pageData?.type;
        if (this.type === '01' || this.type === 'productWithCondition') {
          const content = this?.pageData?.content || {};
          const params = content.conditions || content.condition || {};
          const topProducts = content.topProducts || [];
          const condition = generateFilterArr(params, topProducts);
          return condition;
        } else {
          return null;
        }
      }
    },
    async getPlp(data) {
      this.rerender = false;
      const Plp = CatalogProduct();
      await Plp.init({ ...data });
      this.plp = Plp;
    }
  }
};
</script>
